<template>
  <!--begin::Basic info-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div class="card-header border-0 bg-default">
      <!--begin::Card title-->
      <div class="card-title m-0 d-flex justify-content-between w-100">
        <h3 class="fw-bolder m-0 text-white">Cari Wilayah</h3>
        <div
          class="
            float-end
            d-flex
            align-items-right
            justify-content-between
            w-50
          "
        >
          <select
            name=""
            id=""
            class="form-select me-2"
            @input="getKecamatan"
            v-model="form.kecamatan"
          >
            <option value="" selected>Pilih Kecamatan</option>
            <option
              v-for="(item, i) in kecamatan"
              :key="i"
              :value="item.territory.id"
            >
              {{ item.territory.name }}
            </option>
          </select>
          <select
            name=""
            id=""
            class="form-select me-2"
            @input="getKelurahan"
            v-model="form.kelurahan"
          >
            <option value="" selected>Pilih Kelurahan</option>
            <template v-if="kelurahan">
              <option
                v-for="(item, i) in kelurahan"
                :key="i"
                :value="item.territory.id"
              >
                {{ item.territory.name }}
              </option>
            </template>
          </select>
        </div>
      </div>
      <!--end::Card title-->
    </div>
    <!--begin::Card header-->

    <!--begin::Content-->
    <div id="kt_account_profile_details" class="collapse show">
      <!--begin::Card body-->
      <div class="card-body border-top p-0">
        <!--begin::Input group-->
        <!-- <div class="row mb-6"> -->
        <div style="height: 64vh; width: 100%">
          <l-map
            v-model="zoom"
            v-model:zoom="zoom"
            :zoomAnimation="true"
            :options="{ zoomControl: false }"
            :center="[-6.304159, 106.724997]"
          >
            <l-tile-layer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              layer-type="base"
              name="OpenStreetMap"
            />
            <l-control-layers />
            <l-control-zoom position="bottomright"></l-control-zoom>

            <template v-for="item in markers" :key="item.territory.id">
              <!-- icon -->
              <l-marker
                :lat-lng="[item.territory.latitude, item.territory.longitude]"
              >
                <l-icon :icon-url="icon" :icon-size="iconSize" />
                <l-popup>
                  <div class="fs-5 fw-bolder">
                    Hasil suara di {{ item.territory.name }}
                  </div>
                  <ul class="list-unstyled mb-0 text-gray-600 fw-bold">
                    <li
                      class="
                        mx-2
                        my-2
                        d-flex
                        justify-content-between
                        cursor-pointer
                      "
                      @click="getDetail(item.territory.id, 'pileg')"
                    >
                      <span> Pileg ({{ year }} - {{ year + 5 }}) </span>
                      <span class="ms-7">
                        <i class="bi bi-chevron-right fs-8"></i>
                      </span>
                    </li>
                    <li
                      class="
                        mx-2
                        my-2
                        d-flex
                        justify-content-between
                        cursor-pointer
                        d-none
                      "
                      @click="getDetail(item.territory.id, 'pilkada')"
                    >
                      <span> Pilkada ({{ year }} - {{ year + 5 }}) </span>
                      <span class="ms-7">
                        <i class="bi bi-chevron-right fs-8"></i>
                      </span>
                    </li>
                  </ul>
                </l-popup>
              </l-marker>
            </template>
          </l-map>
        </div>
        <!-- </div> -->
        <!--end::Input group-->
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Basic info-->
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import {
  LMap,
  LIcon,
  LTileLayer,
  LMarker,
  LControlLayers,
  LControlZoom,
  // LTooltip,
  LPopup,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
import CryptoJS from "crypto-js";

export default defineComponent({
  name: "Heatmaps",
  components: {
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LControlZoom,
    // LTooltip,
    LPopup,
  },
  setup() {
    setCurrentPageTitle("Komparasi Peta Suara");

    const store = useStore();

    store.dispatch("AllTerritory", { type: 4 });
    store.dispatch("AllComparison");

    store.commit("SET_ACTIVE_MENU", "map-comparison");

    const router = useRouter();

    const center = ref([-6.304159, 106.724997]);

    const zoom = ref(12);

    const year = 2019;

    const iconSize = ref([50, 50]);

    const path = window.location.origin + "/";
    const icon = path + "other/png/Group 212.png";

    const markers = computed(() => store.state.ComparisonModule.all);
    const kecamatan = computed(() => store.state.TerritoryModule.kecamatan);
    const kelurahan = computed(() => store.state.TerritoryModule.kelurahan);

    watch(markers, () => {
      // console.log(markers.value);
    });

    const form = ref({
      kecamatan: "",
      kelurahan: "",
    });

    const getDetail = (id, to) => {
      const dataId = CryptoJS.AES.encrypt(id.toString(), "PKS.id").toString();
      const dataYear = CryptoJS.AES.encrypt(
        year.toString(),
        "PKS.id"
      ).toString();
      router.push({
        name: "comparison.map.detail." + to,
        params: { id: dataId, year: dataYear },
      });
    };

    const getKecamatan = (el) => {
      form.value.kelurahan = "";
      if (el.target.value) {
        store.dispatch("AllTerritory", { type: 5, parent_id: el.target.value });
        updateDataWilayah(el.target.value);
      } else {
        updateTanpaWilayah();
        store.commit("SET_ALL_TERRITORY", { type: 5, territories: [] });
      }
    };

    const getKelurahan = (el) => {
      if (el.target.value) {
        updateDataWilayah(el.target.value);
      } else {
        updateDataWilayah(form.value.kecamatan);
      }
    };

    const updateDataWilayah = (wilayah) => {
      store.dispatch("AllComparison", {
        territory: wilayah,
      });
    };
    const updateTanpaWilayah = () => {
      store.dispatch("AllComparison");
    };

    return {
      center,
      markers,
      kecamatan,
      kelurahan,
      zoom,
      icon,
      year,
      iconSize,
      form,
      getKecamatan,
      getKelurahan,
      getDetail,
    };
  },
});
</script>
